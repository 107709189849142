/* eslint-disable arrow-body-style */
// eslint-disable-next-line no-unused-vars

import { getCategories, getCategoryBySlug } from "../fake-server/endpoints/categories";
import {
  getDiscountedProducts,
  getFeaturedProducts,
  getLatestProducts,
  // getPopularProducts,
  // getProductBySlug,
  // getProductsList,
  getRelatedProducts,
  // getSuggestions,
  getTopRatedProducts,
} from "../fake-server/endpoints/products";

import { url } from "./apiurl";
import { responseHandler } from "../_heleper/response-handler";

const shopApi = {
  getCategories: (options = {}) => {
    // return fetch(`https://example.com/api/categories.json?${qs.stringify(options)}`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    return getCategories(options);
  },

  getCategoryBySlug: (slug, options = {}) => {
    
    return getCategoryBySlug(slug, options);
  },

  getProductBySlug: (slug) => {
    
    return fetch(`${url}/product/list/${slug}`).then((response) => response.json());
  },

  getRelatedProducts: (slug, options = {}) => {
    
    return getRelatedProducts(slug, options);
  },

  

  getProductsList: (id) => {
    if (id) {
      return fetch(`${url}/product/category/${id}`).then((response) => response.json());
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve([]);
        }, 350);
      });
    }
  },

  getProductsListByChild: (query,options,filters,custom) => {
    const reqOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json" },
      body: JSON.stringify({options,filters,custom})     
    };
    if (query) {
      return fetch(`${url}/product/child-match/${query}`,reqOptions).then((response) => response.json());
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve([]);
        }, 350);
      });
    }
  },

  getFeaturedProducts: (options = {}) => {
    
    return getFeaturedProducts(options);
  },

  getLatestProducts: (options = {}) => {
    
    return getLatestProducts(options);
  },

  getTopRatedProducts: (options = {}) => {
    
    return getTopRatedProducts(options);
  },

  getDiscountedProducts: (options = {}) => {
   
    return getDiscountedProducts(options);
  },

  getPopularProducts: (opt = {}) => {
    return fetch(`${url}/home/best-sell?category=${opt?.category??""}&zone=${opt?.zone??""}&limit=${opt.limit}`).then((response) => response.json());
  },
 
  getSpecialProducts: (options = {}) => {
    return fetch(`${url}/home/special`).then((response) => response.json());
  },

  getVipdealsProducts: (options = {}) => {
    return fetch(`${url}/home/vip-deals`).then((response) => response.json());
  },

  getBelow5000Products: (options = {}) => {
    return fetch(`${url}/home/below/7000`).then((response) => response.json());
  },

  getBanklist: (options = {}) => {
    return fetch(`${url}/bank/lists`).then((response) => response.json());
  },

  getMajorcategory: (options = {}) => {
    return fetch(`${url}/home/major-category`).then((response) => response.json());
  },

  getVideosection: (options = {}) => {
    return fetch(`${url}/video/list`).then((response) => response.json());
  },
  getTestimonial: (options = {}) => {
    return fetch(`${url}/testim/list`).then((response) => response.json());
  },

  getHomeblog: (options = {}) => {
    return fetch(`${url}/blog/list`).then((response) => response.json());
  },

  getHomefaq: (options = {}) => {
    return fetch(`${url}/faq/list`).then((response) => response.json());
  },
  
  getHomeweeklydeal: (options = {}) => {
    return fetch(`${url}/home/weekly-deal`).then((response) => response.json());
  },

  getCatSearchList: (options = {}) => {
    return fetch(`${url}/home/cat-search`).then((response) => response.json());
  },

  getSuggestions: (data) => {
    const reqOptions = {
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body:JSON.stringify(data)
    }
    return fetch(`${url}/product/search`,reqOptions)
      .then(responseHandler)
  },

  getBannerList: (options = {}) => {
    return fetch(`${url}/ad-banner/home-list`).then((response) => response.json());
  },

};

export default shopApi;
