const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  // console.log({value})
  const parts  = value?.split(`; ${name}=`);    
  if (parts.length === 2) return parts.pop().split(';').shift();
};

export function authHeader(){

  // let authToken = JSON.parse(sessionStorage.getItem('accessToken'));
  const authToken = getCookie("tk");

  if(authToken)
    return {'Authorization': authToken};
  else
    return {};
}