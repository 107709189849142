const theme = {
  name: "My UAE Startup",
  fullName: "Ramkumar",
  url: "",
  author: {
    name: "KoCyfrowys",
    profile_url: "",
  },
  contacts: {
    address: "Dubai",
    email: "info@cyfrowy.com",
    phone: "1234567890",
  },
};

export default theme;
