// react
import React, { useState, useRef, useEffect } from 'react';

// third-party
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import {Badge} from "reactstrap"

// application
import Indicator from './Indicator';
import { Person20Svg } from '../../svg';
import { login, logout, session } from '../../store/user';
import Avatar from '../../svg/avatar.webp'
import { authHeader } from '../../_heleper/auth-header';


function IndicatorAccount(props) {

    let dropdown;
    const {isLoading, isLogin, login, logout, session } = props;
    const [userData, setUserData] = useState({ username: "", password: "" });
    const ref = useRef();
    
    useEffect(()=>{
        const tkn = authHeader();
        if(tkn.Authorization && !isLogin){
            session(tkn.Authorization)
            // console.log(tkn)
        }
      // eslint-disable-next-line
    },[])  

    const dashboardLink = isLogin?.user.role === 'vendor' ? '/account/vendor-dashboard' :
               isLogin?.user.role === 'agent' ? '/account/agent-dashboard' : '/account/dashboard';
    const orderLink = isLogin?.user.role === 'vendor' ? '/account/vendor-orders' :
               isLogin?.user.role === 'agent' ? '/account/orders' : '/account/orders'
    const notifiLink = isLogin?.user.role === 'vendor' ? '/account/ven-notifications' :
               isLogin?.user.role === 'agent' ? '/account/orders' : '/account/cus-notifications'


    const handleInputChange = (e) => {
        setUserData(preState => ({ ...preState, [e.target.name]: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        login(userData).then(data => {            
            toast.success(`Hi ${data.user?.firstName}`,{theme:'colored'})
            setUserData({ username: "", password: "" })
            ref.current.toggle();            
        })
    }

    const handleLogout = () => {
        logout();
        ref.current.toggle();
        window.location.reload();
    }

    const handleClose = () =>{
        ref.current.toggle();
    }

    if (isLogin) {
        dropdown = (
            <div className="account-menu">
                <div className="account-menu__divider" />
                <Link to="#" className="account-menu__user">
                    <div className="account-menu__user-avatar">
                        <img src={Avatar} alt="" />
                    </div>
                    <div className="account-menu__user-info">
                        <div className="account-menu__user-name">
                            {isLogin.user?.firstName}{" "}{isLogin.user?.lastName}</div>
                        <div className="account-menu__user-email">{isLogin.user?.email}</div>
                    </div>
                </Link>
                <div className="account-menu__divider" />
                <ul className="account-menu__links">
                    <li><Link to={dashboardLink} onClick={handleClose}>Dashboard</Link></li>
                    <li><Link to="/account/profile" onClick={handleClose}>Edit Profile</Link></li>
                    <li><Link to={orderLink} onClick={handleClose}>Order History</Link></li>
                    <li><Link to={notifiLink}>Notifications 
                    {isLogin?.user.msgcount > 0 && <Badge className='ml-2' color='danger'>
                        {isLogin?.user.msgcount}</Badge>}</Link></li>
                    {/* <li><Link to="">Addresses</Link></li>
                    <li><Link to="">Change Password</Link></li>
                     */}
                </ul>
                <div className="account-menu__divider" />
                <ul className="account-menu__links">
                    <li onClick={handleLogout}><Link to="/">Logout</Link></li>
                </ul>
            </div>
        );

    }
    else {
        dropdown = (
            <div className="account-menu">
                <form className="account-menu__form" onSubmit={handleSubmit} >
                    <div className="account-menu__form-title">Log In to Your Account</div>
                    <div className="form-group">
                        <label htmlFor="header-signin-email" className="sr-only">Email address</label>
                        <input
                            id="header-signin-email"
                            type="text"
                            className="form-control form-control-sm"
                            placeholder="Email / Mobile No"
                            name="username"
                            value={userData.username}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="header-signin-password" className="sr-only">Password</label>
                        <div className="account-menu__form-forgot">
                            <input
                                id="header-signin-password"
                                type="password"
                                className="form-control form-control-sm"
                                placeholder="Password"
                                name="password"
                                value={userData.password}
                                onChange={handleInputChange}
                            />
                            <Link to="/site/forgot" className="account-menu__form-forgot-link">Forgot?</Link>
                        </div>
                    </div>
                    <div className="form-group account-menu__form-button">
                        <button type="submit"
                            className="btn btn-primary btn-sm btn-block"
                            disabled={userData.username === "" || userData.password === ""}
                        >{isLoading ? <span className="spinner-border spinner-border-sm" /> : "Login"}
                        </button>
                    </div>

                    <div className="account-menu__form-link">
                        <Link to="/account/registor"onClick={()=>ref.current.toggle()} >Create An Account</Link>
                    </div>
                </form>
            </div>
        );

    }



    return (
        <Indicator ref={ref} className="tour-login" url="/account" dropdown={dropdown} name1={"Account"} icon={<Person20Svg />} />
    );
}

const mapStateToProps = (state) => ({
    isLogin: state.auth.isLogin,
    isLoading: state.auth.isLoading
});

const mapDispatchToProps = {
    login, logout, session
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorAccount);
